import { Controller } from 'stimulus'

/**
 * タブ切り替えを制御する Stimulus Controller
 */
class TabsController extends Controller {
  /**
   * タブがクリックされた時のイベントを取得
   */
  change (event) {
    const targetBtn = event.currentTarget
    this.openPanel(targetBtn)
  }

  /**
   * タブがクリックされた時にpanelを開く
   */
  openPanel (targetBtn) {
    const btns = this.btnTargets
    const currentIndex = btns.indexOf(targetBtn)
    const panels = this.panelTargets

    this.closeAllPanel()
    targetBtn.setAttribute('aria-selected', 'true')
    targetBtn.setAttribute('tabindex', '0')
    panels[currentIndex].classList.remove(this.hideClass)
  }

  /**
   * panelを開く前に全てのpanelを閉じる
   */
  closeAllPanel () {
    const btns = this.btnTargets
    const panels = this.panelTargets

    btns.forEach((btn) => {
      btn.setAttribute('aria-selected', 'false')
      btn.setAttribute('tabindex', '-1')
    })
    panels.forEach((panel) => {
      panel.classList.add(this.hideClass)
    })
  }
}

/*
 * Configurations
 */

TabsController.targets = ['btn', 'panel']
TabsController.classes = ['hide']

export { TabsController }
