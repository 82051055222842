import { Controller } from 'stimulus'

/**
 * ハンバーガーメニューを制御する Stimulus Controller
 */
class MenuController extends Controller {
  /**
   * メニューボタンがクリックされた時に開閉を切り替える
   */
  clickMenuButton (event) {
    const menuButton = event.currentTarget
    menuButton.classList.toggle(this.openClass)
  }

  /**
   * メニュー内のテキストがクリックされた時に開閉を切り替える
   */
  clickTextLink (event) {
    const navi = document.getElementsByClassName('nav_toggle')
    navi[0].classList.toggle('open')
  }
}

/*
 * Configurations
 */

MenuController.targets = ['menu']
MenuController.classes = ['open']

export { MenuController }
